export default [
  {
    header: 'Menu',
    group: ['applicant'],
  },

  // {
  //   url: null,
  //   title: 'Data Manager',
  //   route: 'applicants-data-manager',
  //   icon: 'LayersIcon',
  //   group: ['applicant'],
  //   children: [
  //     {
  //       url: '/table/events-applications',
  //       title: 'Events Applications',
  //       route: 'candidates-events-applications',
  //       group: ['applicant'],
  //     },
  //   ],
  // },

  {
    url: '/events-list',
    title: 'View Jobs',
    route: 'view-jobs',
    icon: 'CalendarIcon',
    group: ['applicant'],
  },

  {
    url: '/my-applications',
    title: 'Manage Applications',
    route: 'manage-applications',
    icon: 'ListIcon',
    group: ['applicant'],
  },

  {
    url: '/events-manager/calendar',
    title: 'Calendar',
    route: 'events-calendar',
    icon: 'ZapIcon',
    group: ['applicant'],
  },

  {
    url: '/resource',
    title: 'Resource',
    route: 'resources',
    icon: 'BookIcon',
    group: ['applicant'],
  },
  {
    url: '/active-events',
    title: 'Interview Room',
    route: 'interview-room',
    icon: 'ZapIcon',
    group: ['applicant'],
  },

  // {
  //   url: "/view-jobs",
  //   title: "All Jobs",
  //   route: "view-jobs",
  //   icon: "BriefcaseIcon",
  // },

  {
    header: 'Admin',
    group: ['admin'],
  },

  {
    url: null,
    title: 'Analytics',
    icon: 'BarChart2Icon',
    section: 'Analytics',
    group: ['admin'],
    children: [
      {
        url: '/analytics/platform',
        title: 'Platform Analytics',
        route: 'platform-analytics',
        group: ['admin', 'employer', 'agency', 'education_institution'],
      },
      // {
      //   url: '/analytics/organization',
      //   title: 'Corporate Analytics',
      //   route: 'corporate-analytics',
      //   group: ['agency', 'education_institution'],
      // },
    ],
  },

  {
    url: null,
    title: 'Data Manager',
    icon: 'LayersIcon',
    group: ['agency'],
    section: 'Data Manager',
    children: [
      {
        url: '/table/users',
        title: 'All Users',
        route: 'candidates-data',
        group: ['admin', 'employer', 'agency', 'education_institution'],
      },
      {
        url: '/table/jobs',
        title: 'All Jobs',
        route: 'jobs-data',
        group: ['admin', 'employer', 'agency', 'education_institution'],
      },
      // {
      //   url: '/table/scheduled-jobs',
      //   title: 'All Scheduled Jobs',
      //   route: 'scheduled-jobs-data',
      //   group: ['admin', 'employer', 'agency', 'education_institution'],
      // },
      {
        url: '/table/applications',
        title: 'All Applications',
        route: 'applications-data',
        group: ['admin', 'employer', 'agency', 'education_institution'],
      },
      // {
      //   url: '/table/biweekly',
      //   title: 'Bi-Weekly Data',
      //   route: 'bi-weekly-data',
      //   group: ['admin'],
      // },
      // {
      //   url: '/table/events-data',
      //   title: 'Events Data',
      //   route: 'events-data',
      //   group: ['admin'],
      // },
      // {
      //   url: '/table/crm-data',
      //   title: 'CRM Data',
      //   route: 'crm-data',
      //   group: ['admin'],
      // },
    ],
  },
  {
    url: null,
    title: 'Data Manager',
    icon: 'LayersIcon',
    group: ['employer', 'education_institution'],
    section: 'Data Manager',
    children: [
      {
        url: '/table/users',
        title: 'All Users',
        route: 'candidates-data',
        group: ['admin', 'employer', 'agency', 'education_institution'],
      },
      {
        url: '/table/events-data',
        title: 'Events Data',
        route: 'events-data',
        group: ['admin'],
      },
    ],
  },
  // {
  //   url: null,
  //   title: 'Audit Log',
  //   icon: 'BookIcon',
  //   group: ['admin'],
  //   section: 'Audit Log',
  //   children: [
  //     {
  //       url: '/audit/access-logs',
  //       title: 'Access Logs',
  //       route: 'access-logs',
  //       icon: 'LockIcon',
  //     },
  //     {
  //       url: '/audit/change-logs',
  //       title: 'Change Logs',
  //       route: 'change-logs',
  //       icon: 'ZapIcon',
  //     },
  //   ],
  // },
  {
    url: null,
    title: 'User Manager',

    icon: 'UsersIcon',
    group: ['hiring'],
    section: 'User Manager',
    children: [
      {
        url: '/user-manager',
        title: 'Internal Users',
        route: 'user-manager',
        icon: 'UsersIcon',
        group: ['admin'],
      },
      {
        url: '/user-manager/companies',
        title: 'Others',
        route: 'companies',
        icon: 'UsersIcon',
        group: ['admin'],
      },
    ],
  },
  // {
  //   header: 'Apps',
  //   group: ['admin'],
  // },

  // {
  //   url: null,
  //   title: 'Events Manager',
  //   icon: 'CalendarIcon',
  //   group: ['admin'],
  //   section: 'Events Manager',
  //   children: [
  //     {
  //       url: '/events-manager/events',
  //       title: 'Manage Events',
  //       route: 'manage-events',
  //       icon: 'LockIcon',
  //     },
  //     {
  //       url: '/events-manager/calendar',
  //       title: 'Calendar',
  //       route: 'events-calendar',
  //       icon: 'ZapIcon',
  //     },
  //     {
  //       url: '/events-manager/interview-preparation',
  //       title: 'Resource',
  //       route: 'interview-prep',
  //       icon: 'FileIcon',
  //     },

  //     {
  //       url: '/events-manager/rooms',
  //       title: 'Interview Room',
  //       route: 'interview-room',
  //       icon: 'ZapIcon',
  //     },
  //     // {
  //     //   url: "/events-manager/insights",
  //     //   title: "Insights",
  //     //   route: "event-insights",
  //     //   icon: "ZapIcon",
  //     //   group: ["admin"],
  //     // },
  //   ],
  // },
  {
    url: null,
    title: 'Audit Log',
    icon: 'BookIcon',
    group: ['admin', 'agency', 'employer'],
    section: 'Audit Log',
    children: [
      {
        url: '/audit/access-logs',
        title: 'Access Logs',
        route: 'access-logs',
        icon: 'LockIcon',
        group: ['admin', 'agency', 'employer'],
      },
      {
        url: '/audit/change-logs',
        title: 'Change Logs',
        route: 'change-logs',
        icon: 'ZapIcon',
        group: ['admin', 'agency', 'employer'],
      },
    ],
  },
  {
    url: null,
    title: 'User Manager',

    icon: 'UsersIcon',
    group: ['agency'],
    section: 'User Manager',
    children: [
      {
        url: '/user-manager',
        title: 'Internal Users',
        route: 'user-manager',
        icon: 'UsersIcon',
        group: ['admin'],
      },
      // {
      //   url: '/user-manager/companies',
      //   title: 'Others',
      //   route: 'companies',
      //   icon: 'UsersIcon',
      //   group: ['admin'],
      // },
    ],
  },

  {
    url: null,
    title: 'User Manager',

    icon: 'UsersIcon',
    group: ['employer', 'education_institution'],
    section: 'User Manager',
    children: [
      {
        url: '/user-manager',
        title: 'Internal Users',
        route: 'user-manager',
        icon: 'UsersIcon',
        group: ['admin'],
      },
    ],
  },
  // {
  //   url: '/applicant-tracking',
  //   title: 'ATS System',
  //   route: 'applicant-tracking-system',
  //   icon: 'GlobeIcon',
  //   group: ['admin'],
  //   section: 'ATS System',
  // },
  // {
  //   url: null,
  //   title: 'Marketplace',

  //   icon: 'ShoppingBagIcon',
  //   group: ['applicant', 'employer', 'agency', 'education_institution'],
  //   section: 'Marketplace',
  //   children: [
  //     {
  //       url: '/marketplace',
  //       title: 'Marketplace',
  //       route: 'marketplace',
  //       icon: 'ShoppingBagIcon',
  //       group: ['applicant', 'employer', 'agency', 'education_institution'],
  //     },
  //     {
  //       url: '/wishlist',
  //       title: 'Wishlist',
  //       route: 'marketplace-wishlist',
  //       icon: 'StarIcon',
  //       group: ['applicant', 'employer', 'agency', 'education_institution'],
  //     },
  //     {
  //       url: '/checkout',
  //       title: 'Checkout',
  //       route: 'marketplace-checkout',
  //       icon: 'ShoppingCartIcon',
  //       group: ['applicant', 'employer', 'agency', 'education_institution'],
  //     },
  //     {
  //       url: '/marketplace-my-checkouts',
  //       title: 'My Checkouts',
  //       route: 'marketplace-checkouts-list',
  //       icon: 'PackageIcon',
  //       group: ['applicant', 'employer', 'agency', 'education_institution'],
  //     },
  //     {
  //       url: '/marketplace-manage-products',
  //       title: 'Manage Products',
  //       route: 'marketplace-manage-products',
  //       icon: 'EditIcon',
  //       group: ['employer', 'agency', 'education_institution'],
  //     },
  //     {
  //       url: '/marketplace-my-orders',
  //       title: 'Orders',
  //       route: 'marketplace-orders-list',
  //       icon: 'PackageIcon',
  //       group: ['applicant', 'employer', 'agency', 'education_institution'],
  //     },
  //   ],
  // },

  // {
  //   url: '/employer-match',
  //   title: 'EA Directory',
  //   route: 'ea-directory',
  //   icon: 'SearchIcon',
  //   group: ['employer'],
  //   section: 'EA Directory',
  // },

  /* {
    url: '/corporate-directory',
    title: 'Corporate Directory',
    route: 'corporate-directory',
    icon: 'SearchIcon',
    group: ['agency'],
    section: 'Corporate Directory',
  },
  {
    url: '/email',
    title: 'E-Mail',
    route: 'email',
    icon: 'MailIcon',
    group: ['agency'],
    section: 'Email',
  }, */
  /* {
    url: '/corporate-directory-email',
    title: 'SMS',
    route: 'corporate-directory-email',
    icon: 'MessageSquareIcon',
    group: ['agency'],
    section: 'Corporate Directory',
  }, */

  // {
  //   url: null,
  //   title: 'Corporate Directory',

  //   icon: 'SearchIcon',
  //   group: ['agency'],
  //   section: 'Corporate Directory',
  //   children: [
  //     {
  //       url: '/corporate-directory',
  //       title: 'Intel',
  //       route: 'corporate-directory',
  //       icon: 'SearchIcon',
  //       group: ['admin'],
  //     },
  //     // {
  //     //   url: '/email',
  //     //   title: 'E-Mail',
  //     //   route: 'email',
  //     //   icon: 'MailIcon',
  //     //   group: ['admin'],
  //     // },
  //   ],
  // },

  // {
  //   url: '/finance',
  //   title: 'Finance',
  //   route: 'finance',
  //   icon: 'BriefcaseIcon',
  //   group: ['admin', 'agency', 'employer'],
  //   section: 'Finance',
  // },

  // {
  //   url: '/help-desks',
  //   title: 'Help Desk',
  //   route: 'help-desks-index',
  //   icon: 'UserIcon',
  //   group: ['admin'],
  //   section: 'HelpDesk',
  //   children: [
  //     {
  //       url: '/help-desks',
  //       title: 'Tickets',
  //       route: 'help-desks-index',
  //       icon: 'UsersIcon',
  //       group: ['admin'],
  //     },
  //     {
  //       url: '/help-desks/issues-queues',
  //       title: 'Issue',
  //       // route: 'help-desks-issues-live-chat',
  //       route: 'help-desks-issues-view',
  //       icon: 'MessageSquareIcon',
  //       group: ['admin'],
  //     },
  //   ],
  // },

  // {
  //   header: 'Other Apps',
  //   group: ['applicant', 'employer', 'agency'],
  // },
  // {
  //   url: '/referral',
  //   title: 'Referrals',
  //   route: 'referral',
  //   icon: 'Share2Icon',
  //   group: ['applicant', 'agency', 'education_institution', 'admin'],
  // },

  // {
  //   url: '/notifications',
  //   title: 'Notifications',
  //   route: 'notifications',
  //   section: 'notifications',
  //   icon: 'BellIcon',
  //   group: ['applicant', 'employer', 'agency', 'education_institution', 'admin'],
  // },
  // {
  //   url: '/settings',
  //   title: 'Settings',
  //   section: 'settings',
  //   route: 'settings',
  //   icon: 'SettingsIcon',
  //   group: ['agency', 'admin', 'employer'],
  // },
  // {
  //   url: '/user-guide',
  //   title: 'User Guide',
  //   route: 'user-guide',
  //   icon: 'BookOpenIcon',
  //   group: ['applicant', 'employer', 'agency', 'education_institution'],
  //   allowed_companies: ['PersolKelly'],
  // },
]
