<template>
  <p class="clearfix mb-0 text-din-regular text-pantone-445">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      <span>Powered by
        <b-link
          class="text-pantone-445"
          href="https://headhuntershq.com"
          target="_blank"
          rel="nofollow"
        >HeadHunters HQ</b-link>
      </span>
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
