var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-menu menu-fixed menu-accordion menu-shadow",class:[
    {
      expanded:
        !_vm.isVerticalMenuCollapsed ||
        (_vm.isVerticalMenuCollapsed && _vm.isMouseHovered),
    },
    _vm.skin === 'semi-dark' ? 'menu-dark' : 'menu-light' ],on:{"mouseenter":function($event){return _vm.updateMouseHovered(true)},"mouseleave":function($event){return _vm.updateMouseHovered(false)}}},[_c('div',{staticClass:"navbar-header expanded"},[_vm._t("header",function(){return [_c('ul',{staticClass:"nav navbar-nav flex-row"},[_c('li',{staticClass:"nav-item mr-auto"},[(
              !_vm.isVerticalMenuCollapsed
                || (_vm.isVerticalMenuCollapsed && _vm.isMouseHovered)
            )?_c('b-img',{staticClass:"mt-2",staticStyle:{"width":"180px"},attrs:{"src":_vm.appLogoImage,"alt":"logo"}}):_c('div',{staticClass:"d-flex justify-content-center align-items-center mt-1 sm-logo-container"},[_c('b-img',{attrs:{"src":_vm.collapsedAppLogoImage,"alt":"logo"}})],1)],1),_c('li',{staticClass:"nav-item nav-toggle"},[_c('b-link',{staticClass:"nav-link modern-nav-toggle"},[_c('feather-icon',{staticClass:"d-block d-xl-none",attrs:{"icon":"XIcon","size":"20"},on:{"click":_vm.toggleVerticalMenuActive}}),_c('feather-icon',{staticClass:"d-none d-xl-block collapse-toggle-icon",attrs:{"icon":_vm.collapseTogglerIconFeather,"size":"20"},on:{"click":_vm.toggleCollapsed}})],1)],1)])]},{"toggleVerticalMenuActive":_vm.toggleVerticalMenuActive,"toggleCollapsed":_vm.toggleCollapsed,"collapseTogglerIcon":_vm.collapseTogglerIcon})],2),_c('div',{staticClass:"shadow-bottom",class:{ 'd-block': _vm.shallShadowBottom }}),_c('vue-perfect-scrollbar',{staticClass:"main-menu-content scroll-area",attrs:{"settings":_vm.perfectScrollbarSettings,"tagname":"ul"},on:{"ps-scroll-y":function (evt) {
      _vm.shallShadowBottom = evt.srcElement.scrollTop > 0;
    }}},[_c('vertical-nav-menu-items',{staticClass:"navigation navigation-main",attrs:{"items":_vm.updatedMenu}}),(!_vm.isVerticalMenuCollapsed)?_c('div',{staticClass:"container bg-white fixed-bottom"},[(_vm.$store.state.auth.ActiveUser &&
          _vm.$store.state.auth.ActiveUser.email_address.includes('hhqmail')
        )?_c('div',{staticClass:"container mb-6"},[_c('p',{staticClass:"text-dark mt-1"},[_vm._v(" For any Question or Feedback: ")]),_c('p',[_c('b-link',{staticClass:"mb-8",attrs:{"href":"mailto:jobsecurity@e2i.com.sg"}},[_vm._v(" jobsecurity@e2i.com.sg ")]),_c('br'),_c('span',{staticClass:"text-dark mt-2"},[_vm._v("or")]),_c('b-link',{attrs:{"href":"tel:+6562067282"}},[_vm._v(" +65 6206 7282 ")])],1),_c('p',{staticClass:"text-dark"},[_vm._v(" For Technical Support: ")]),_c('b-link',{staticClass:"mb-8",attrs:{"href":"mailto:support@headhuntershq.com"}},[_vm._v(" support@headhuntershq.com ")]),_c('br'),_c('br')],1):_c('div',{staticClass:"container"},[_c('p',{staticClass:"text-dark mt-2"},[_vm._v(" For Technical Support: ")]),_c('b-link',{staticClass:"mb-2",attrs:{"href":"mailto:support@headhuntershq.com"}},[_vm._v(" support@headhuntershq.com ")]),_c('br'),_c('br')],1)]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }